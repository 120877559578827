import { CreateActionParams } from '../actions';

export type closeModal = () => void;

export function createCloseModalAction({
  context,
  getControllerState,
}: CreateActionParams): closeModal {
  return () => {
    const [, setState] = getControllerState();

    setState({ modalContext: undefined });
  };
}
