import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';
import { BookingsGroup } from '../../types';

export type ClickOnBookAgain = (args: { group: BookingsGroup }) => void;

export function createClickOnBookAgainAction({
  context,
}: CreateActionParams): ClickOnBookAgain {
  return async ({ group }) => {
    const { flowAPI } = context;
    const { wixCodeApi, platformAPIs } = flowAPI.controllerConfig;
    const slug = group.bookings[0].offering?.urlName;

    try {
      await navigateToBookingsCalendarPage(wixCodeApi, platformAPIs as any, {
        serviceSlugOrBasket: slug,
      });
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
